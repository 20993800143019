/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Suspense } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "../assets/scss/main.scss"
import Footer from "./footer"
import Header from "./header"
import { useState } from 'react';


const Layout = ({ children }) => {


  const [loading, setLoading] = useState(true);

  setTimeout(()=>{
    setLoading(false);
  },2000);

  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  return (

    <>
    {loading ? (
      <div id="preloader">
        <div className="preloader-wrap">
          <div className="thecube">
            <div className="cube c1"></div>
            <div className="cube c2"></div>
            <div className="cube c4"></div>
            <div className="cube c3"></div>
          </div>
        </div>
      </div>
    ) : (
      <div id="page-wrapper  fade-in">
        {/* <Header siteTitle={data.site.siteMetadata.title} /> */}
  
        <div className="main">{children}</div>
  
        {/* <Footer /> */}
      </div>
    )}
  </>

  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
